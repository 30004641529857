import { render, staticRenderFns } from "./FacetItem.vue?vue&type=template&id=b66c1614&scoped=true&"
import script from "./FacetItem.vue?vue&type=script&lang=ts&"
export * from "./FacetItem.vue?vue&type=script&lang=ts&"
import style0 from "./FacetItem.vue?vue&type=style&index=0&id=b66c1614&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.10.1_c85c30100bd0960bb72eb48aec02b84c/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b66c1614",
  null
  
)

export default component.exports