
import { defineComponent, PropType } from 'vue'

// Composables
import { UseFacetedSearch, Facet } from '@/use/faceted-search'

// Components
import SearchFacets from '@/components/SearchFacets.vue'

export default defineComponent({
  name: 'AlertsSidebar',
  components: { SearchFacets },

  props: {
    facetedSearch: {
      type: Object as PropType<UseFacetedSearch>,
      required: true,
    },
    facets: {
      type: Array as PropType<Facet[]>,
      required: true,
    },
  },

  setup() {
    return {}
  },
})
