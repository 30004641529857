
import { defineComponent, computed, PropType } from 'vue'

// Composables
import { NotifChannel } from '@/alerting/use-notif-channels'

import NotifChannelsTableRow from '@/alerting/NotifChannelsTableRow.vue'

export default defineComponent({
  name: 'NotifChannelsTable',
  components: { NotifChannelsTableRow },

  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    channels: {
      type: Array as PropType<NotifChannel[]>,
      required: true,
    },
  },

  setup() {
    const headers = computed(() => {
      const headers = []
      headers.push({ text: 'Name', value: 'name', sortable: true, align: 'start' })
      headers.push({ text: 'Type', value: 'type', sortable: true, align: 'start' })
      headers.push({ text: 'State', value: 'state', sortable: true, align: 'center' })
      headers.push({ text: 'Actions', value: 'actions', sortable: false, align: 'center' })
      return headers
    })

    return { headers }
  },
})
