
import { defineComponent } from 'vue'

// Components
import HelpLinks from '@/components/HelpLinks.vue'

export default defineComponent({
  name: 'NotFoundPage',
  components: { HelpLinks },

  setup() {},
})
