import { render, staticRenderFns } from "./AlertsSidebar.vue?vue&type=template&id=03c36588&scoped=true&"
import script from "./AlertsSidebar.vue?vue&type=script&lang=ts&"
export * from "./AlertsSidebar.vue?vue&type=script&lang=ts&"
import style0 from "./AlertsSidebar.vue?vue&type=style&index=0&id=03c36588&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/.pnpm/vue-loader@15.10.1_c85c30100bd0960bb72eb48aec02b84c/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "03c36588",
  null
  
)

export default component.exports