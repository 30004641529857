
import { defineComponent, PropType } from 'vue'

// Composables
import { UsePager } from '@/use/pager'

// Components
import XPager from '@/components/XPager.vue'

export default defineComponent({
  name: 'XPagination',
  components: { XPager },

  props: {
    pager: {
      type: Object as PropType<UsePager>,
      required: true,
    },
    totalVisible: {
      type: [String, Number],
      default: 7,
    },
    showPager: {
      type: Boolean,
      default: true,
    },
  },
})
