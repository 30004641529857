import { render, staticRenderFns } from "./MonitorList.vue?vue&type=template&id=88e61e1e&scoped=true&"
import script from "./MonitorList.vue?vue&type=script&lang=ts&"
export * from "./MonitorList.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.10.1_c85c30100bd0960bb72eb48aec02b84c/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "88e61e1e",
  null
  
)

export default component.exports