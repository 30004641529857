
import { defineComponent, shallowRef, PropType } from 'vue'

// Composables
import { UseUql } from '@/use/uql'

// Components
import SearchableList from '@/components/SearchableList.vue'

export default defineComponent({
  name: 'DashGroupingMenu',
  components: { SearchableList },

  props: {
    uql: {
      type: Object as PropType<UseUql>,
      required: true,
    },
    attrKeys: {
      type: Array as PropType<string[]>,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  setup(props) {
    const menu = shallowRef(false)

    function groupBy(AttrKey: string) {
      const editor = props.uql.createEditor()
      editor.add(`group by ${AttrKey}`)
      props.uql.commitEdits(editor)

      menu.value = false
    }

    return {
      menu,
      groupBy,
    }
  },
})
