import { render, staticRenderFns } from "./HelpLinks.vue?vue&type=template&id=4813596d&scoped=true&"
import script from "./HelpLinks.vue?vue&type=script&lang=ts&"
export * from "./HelpLinks.vue?vue&type=script&lang=ts&"
import style0 from "./HelpLinks.vue?vue&type=style&index=0&id=4813596d&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/.pnpm/vue-loader@15.10.1_c85c30100bd0960bb72eb48aec02b84c/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4813596d",
  null
  
)

export default component.exports