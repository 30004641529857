
import { defineComponent, shallowRef, PropType } from 'vue'

// Composables
import { useSnackbar } from '@/use/snackbar'
import { useUser } from '@/org/use-users'
import { useNotifChannelManager, EmailNotifChannel } from '@/alerting/use-notif-channels'

export default defineComponent({
  name: 'NotifChannelEmailForm',

  props: {
    channel: {
      type: Object as PropType<EmailNotifChannel>,
      required: true,
    },
  },

  setup(props) {
    const snackbar = useSnackbar()
    const user = useUser()
    const man = useNotifChannelManager()

    const form = shallowRef()
    const isValid = shallowRef(true)

    function submit() {
      if (!form.value.validate()) {
        return Promise.reject()
      }

      man.emailUpdate(props.channel).then(() => {
        snackbar.notifySuccess(`Email notification settings have been updated successfully`)
      })
    }

    return {
      user,
      man,

      form,
      isValid,
      submit,
    }
  },
})
