
import { defineComponent } from 'vue'

// Composables
import { useConfirm } from '@/use/confirm'

export default defineComponent({
  name: 'GlobalConfirm',

  setup() {
    return useConfirm()
  },
})
