
import { defineComponent, computed } from 'vue'

// Composables
import { useProject } from '@/org/use-projects'

export default defineComponent({
  name: 'Alerting',

  setup() {
    const project = useProject()

    const breadcrumbs = computed(() => {
      const bs: any[] = []

      bs.push({
        text: project.data?.name ?? 'Project',
        to: {
          name: 'ProjectShow',
        },
      })

      bs.push({ text: 'Alerting' })

      return bs
    })

    return { breadcrumbs }
  },
})
