
import { filter as fuzzyFilter } from 'fuzzaldrin-plus'
import { defineComponent, shallowRef, computed, PropType } from 'vue'

// Composables
import { SavedView, UseSavedViews } from '@/tracing/views/use-saved-views'

// Components
import SavedViewsList from '@/tracing/views/SavedViewsList.vue'

export default defineComponent({
  name: 'ListViewDrawer',
  components: { SavedViewsList },

  props: {
    views: {
      type: Object as PropType<UseSavedViews>,
      required: true,
    },
  },

  setup(props) {
    const searchInput = shallowRef('')
    const drawer = shallowRef(false)

    const filteredViews = computed((): SavedView[] => {
      if (!searchInput.value) {
        return props.views.items
      }
      return fuzzyFilter(props.views.items, searchInput.value, { key: 'name' })
    })

    function onClickOutside() {
      drawer.value = false
    }

    function closeConditional() {
      return drawer.value
    }

    return {
      searchInput,
      filteredViews,

      drawer,
      onClickOutside,
      closeConditional,
    }
  },
})
