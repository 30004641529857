
import { defineComponent, computed, PropType } from 'vue'

// Composables
import { NotifChannelState } from '@/alerting/use-notif-channels'

export default defineComponent({
  name: 'NotifChannelStateAvatar',

  props: {
    state: {
      type: String as PropType<NotifChannelState>,
      required: true,
    },
  },

  setup(props) {
    const attrs = computed(() => {
      switch (props.state) {
        case NotifChannelState.Delivering:
          return { color: 'success' }
        case NotifChannelState.Disabled:
          return { color: 'error', dark: true }
        default:
          return { color: 'grey' }
      }
    })

    return { attrs }
  },
})
