
import { defineComponent, PropType } from 'vue'

// Composables
import { StateCount } from '@/alerting/use-monitors'

// Components
import MonitorStateAvatar from '@/alerting/MonitorStateAvatar.vue'

export default defineComponent({
  name: 'MonitorStateCounts',
  components: { MonitorStateAvatar },

  props: {
    states: {
      type: Array as PropType<StateCount[]>,
      required: true,
    },
  },
})
