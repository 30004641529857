
import { defineComponent, reactive } from 'vue'

// Composables
import { useTitle } from '@vueuse/core'
import { useEmailChannel } from '@/alerting/use-notif-channels'

// Components
import NotifChannelEmailForm from '@/alerting/NotifChannelEmailForm.vue'

export default defineComponent({
  name: 'EmailNotifications',
  components: { NotifChannelEmailForm },

  setup() {
    useTitle('Email notifications')
    const email = useEmailChannel()

    return { email, reactive }
  },
})
