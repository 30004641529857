
import { defineComponent } from 'vue'

// Components
import HelpCard from '@/metrics/HelpCard.vue'

export default defineComponent({
  name: 'MetricsHelp',
  components: { HelpCard },
})
