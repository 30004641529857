import { render, staticRenderFns } from "./TraceTabs.vue?vue&type=template&id=713643b8&scoped=true&"
import script from "./TraceTabs.vue?vue&type=script&lang=ts&"
export * from "./TraceTabs.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/.pnpm/vue-loader@15.10.1_c85c30100bd0960bb72eb48aec02b84c/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "713643b8",
  null
  
)

export default component.exports