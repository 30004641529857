
import { defineComponent } from 'vue'

// Composables
import { useSnackbar } from '@/use/snackbar'

export default defineComponent({
  name: 'GlobalSnackbar',

  setup() {
    return { snackbar: useSnackbar() }
  },
})
