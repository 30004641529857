
import { defineComponent, computed } from 'vue'

export default defineComponent({
  name: 'UptraceQueryChip',

  props: {
    query: {
      type: String,
      required: true,
    },
    error: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    deletable: {
      type: Boolean,
      default: false,
    },
  },

  setup(props) {
    const info = computed(() => {
      return splitKeywordExpr(props.query)
    })
    return { info }
  },
})

const GROUP_BY = /^group\s+by\s+(.+)/i
const WHERE = /^where\s+(.+)/i

function splitKeywordExpr(s: string) {
  let m = s.match(GROUP_BY)
  if (m) {
    return { keyword: 'group by', expr: m[1] }
  }

  m = s.match(WHERE)
  if (m) {
    return { keyword: 'where', expr: m[1] }
  }

  return { keyword: '', expr: s }
}
