
import { defineComponent, computed, PropType } from 'vue'

// Composables
import { MetricAlert } from '@/alerting/use-alerts'

export default defineComponent({
  name: 'MetricMonitorTrigger',

  props: {
    alert: {
      type: Object as PropType<MetricAlert>,
      required: true,
    },
    verbose: {
      type: Boolean,
      default: false,
    },
  },

  setup(props) {
    const unit = computed(() => {
      return props.alert.params.monitor.columnUnit
    })

    return { unit }
  },
})
